var email = document.getElementById("email-2");
var tick = document.getElementById("tick-2");
var emailError = document.getElementById("email-err-2");
var password = document.getElementById("password-2");
var eyeClosed = document.getElementById("eye-closed-2");
var eyeOpen = document.getElementById("eye-open-2");
var passwordError = document.getElementById("password-err-2");
var getStarted = document.getElementById("get_started-2");
var gsText = document.getElementById("gs_txt-2");
var gsLoader = document.getElementById("gs_loader-2");
var auth = "";
var tk = "";
var path = "https://api.squadcast.com";
var env = window.location.href.slice(window.location.href.indexOf("?") + 1);
var regError = document.getElementById("reg-err-2");
var host = window.location.host;
var emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
var eu = false;
var tickLoader = document.getElementById("tick-loader");

var fname = document.getElementById("fname");
var lname = document.getElementById("lname");
var org = document.getElementById("org");
var phone = document.getElementById("phone");
var fnameError = document.getElementById("fname-err");
var lnameError = document.getElementById("lname-err");
var orgError = document.getElementById("org-err");
var update = document.getElementById("get_update");
var updateText = document.getElementById("update_txt");
var updateLoader = document.getElementById("update_loader");
var error = document.getElementById("gen-err");
var phoneNo;
var iBtn = document.getElementById("i-btn");
var toolTip = document.getElementById("tooltip");
var geo = Intl.DateTimeFormat().resolvedOptions().timeZone;
//var path = 'https://api.squadcast.com';
var regx = /^[a-zA-Z ]{1,30}$/;
const MAX_PASSWORD_LENGTH = 128;
const MIN_PASSWORD_LENGTH = 10;
const RECAPTCHA_V3_SITE_KEY = "6LcgNHEgAAAAABK8engp0tOBPaggTRZ83yVFU0Tf"
//var timerRunning = true;
//var timer;
//var email = localStorage.getItem("email");

//window.history.pushState('', '', '/set-up');

//change logo on tablet and mobile devices
if (window.innerWidth <= 991) {
  document.getElementById("logo").src = "assets/Primary_Logo@2x_white.svg";
}

//createIncident is defined in GTM
if (env == "xyz") {
  path = "https://api.squadcast.xyz";
} else if (env == "tech") {
  path = "https://api.squadcast.tech";
} else {
  env = "com";
}

// //reCAPTCHA token
// window.onloadCallback = function onloadCallback(token) {
//   console.log(tk);
//   tk = token;
//   console.log(token, "g captcha token");
//   if (isPasswordValid() && tick.style.display == "inline") {
//     getStarted.removeAttribute("disabled");
//   }
// };

// //reCAPTCHA token expired
// window.handleCaptchaExpired = function handleCaptchaExpired() {
//   getStarted.setAttribute("disabled", "");
// };

//Timer starts
var minutesLabel = 0;
var secondsLabel = 0;
var totalSeconds = 0;
var interval;

function setTime() {
  ++totalSeconds;
  secondsLabel = pad(totalSeconds % 60);
  minutesLabel = pad(parseInt(totalSeconds / 60));
}

function pad(val) {
  var valString = val + "";
  if (valString.length < 2) {
    return "0" + valString;
  } else {
    return valString;
  }
}

function startTimer() {
  interval = setInterval(setTime, 1000);
}

function stopTimer(api) {
  clearInterval(interval);
  timerIncident(api);
  minutesLabel = 0;
  secondsLabel = 0;
  totalSeconds = 0;
}

function timerIncident(ev) {
  try {
    if (minutesLabel > 0 || secondsLabel > 5) {
      createIncident(
        ev + " " + "Latency",
        "Time Taken: " +
          minutesLabel +
          ":" +
          secondsLabel +
          "\n\nEmail: " +
          email.value +
          "\n\nLogRocket URL: " +
          LogRocket.recordingURL
      );
    }
  } catch (e) {
    console.log("GTM / Logrocket Error");
  }
}
//Timer ends

//Date Timer starts

var date;
var date1;
var reqTime;
var resTime;

function calcDiff(apiLabel) {
  var diff = (resTime - reqTime) / 1000;
  console.log(diff);
  if (diff > 5) {
    createIncident(
      apiLabel + " " + "Latency",
      "Time Taken: " +
        diff +
        " Seconds\n\nEmail: " +
        email.value +
        "\n\nLogRocket URL: " +
        LogRocket.recordingURL
    );
  }
}

//Date Timer ends

function emailCheck() {
  tick.style.display = "none";
  getStarted.setAttribute("disabled", "");
  if (!email.value || !emailRegex.test(email.value)) {
    tick.style.display = "none";
    //eyeClosed.classList.add('err');
    //eyeOpen.classList.add('err');
    email.classList.add("err");
    emailError.innerText = "Please enter a valid email address to proceed.";
    emailError.style.display = "block";
    getStarted.setAttribute("disabled", "");
  } else if (email.value) {
    var domain = email.value.split("@")[1].split(".")[0].toLowerCase();
    if (
      domain == "gmail" ||
      domain == "yahoo" ||
      domain == "googlemail" ||
      domain == "hotmail" ||
      domain == "onmail" ||
      domain == "outlook" ||
      domain == "zoho" ||
      domain == "aol" ||
      domain == "icloud" ||
      domain == "proton" ||
      domain == "email" ||
      domain == "mail"
    ) {
      tick.style.display = "none";
      //eyeClosed.classList.add('err');
      //eyeOpen.classList.add('err');
      email.classList.add("err");
      emailError.innerText =
        "Please sign-up with a valid business email address.";
      emailError.style.display = "block";
      getStarted.setAttribute("disabled", "");
      try {
        createIncident(
          "Personal Email Signup Attempt",
          "Email: " +
            email.value +
            "\n\nLogRocket URL: " +
            LogRocket.recordingURL
        );
      } catch (error) {
        console.log("GTM error");
      }
    } else {
      //eyeClosed.classList.remove('err');
      //eyeOpen.classList.remove('err');
      email.classList.remove("err");
      emailError.innerText = "";
      emailError.style.display = "none";
      if (tk && isPasswordValid()) {
        //email.onblur = ''; -> emailCheck()
        tickLoader.style.display = "inline";
        validate();
      }
    }
  } else {
    //eyeClosed.classList.remove('err');
    //eyeOpen.classList.remove('err');
    email.classList.remove("err");
    emailError.innerText = "";
    emailError.style.display = "none";
    if (tk && isPasswordValid()) {
      //email.onblur = ''; -> emailCheck()
      tickLoader.style.display = "inline";
      validate();
    }
  }
}

email.onblur = function () {
  emailCheck();
  if (emailError.style.display != "block") {
    tickLoader.style.display = "inline";
    validate();
  }
};

getStarted.onclick = function () {
  getStarted.setAttribute("disabled", "");
  gsText.style.visibility = "hidden";
  gsLoader.style.display = "block";
  if (document.getElementById("eu-2").checked == true) {
    path = "https://api.eu.squadcast.com";
    eu = true;
  } else {
    if (env == "xyz") {
      path = "https://api.squadcast.xyz";
    } else if (env == "tech") {
      path = "https://api.squadcast.tech";
    } else {
      path = "https://api.squadcast.com";
    }
  }
  register();
};

password.onblur = function () {
  if (isPasswordValid()) {
    passwordError.style.display = "none";
    passwordError.innerText = "";
    eyeClosed.classList.remove("perr");
    eyeOpen.classList.remove("perr");
    password.classList.remove("err");
    if (tk && tick.style.display == "inline") {
      getStarted.removeAttribute("disabled");
    } else {
      getStarted.setAttribute("disabled", "");
    }
  } else {
    passwordError.innerText =
      "Password cannot be empty. Ensure it has a minimum of 10 and maximum of 128 characters.";
    passwordError.style.display = "block";
    eyeClosed.classList.add("perr");
    eyeOpen.classList.add("perr");
    password.classList.add("err");
    getStarted.setAttribute("disabled", "");
  }
};

eyeClosed.onclick = function () {
  password.type = "text";
  eyeClosed.style.display = "none";
  eyeOpen.style.display = "inline-block";
};

eyeOpen.onclick = function () {
  password.type = "password";
  eyeOpen.style.display = "none";
  eyeClosed.style.display = "inline-block";
};

function validate() {
  var params = {
    email: email.value,
  };
  var url = path + "/v3/accounts/register/validate";
  var http = new XMLHttpRequest();
  try {
    http.open("PUT", url);
    http.setRequestHeader("Content-type", "application/json");
    http.send(JSON.stringify(params));
    date = new Date();
    reqTime = date.getTime();
    //startTimer();
    http.onload = function () {
      date1 = new Date();
      resTime = date1.getTime();
      calcDiff("Validate API US");
      //stopTimer('Validate API US');
      if (http.status != 200 && http.status != 204) {
        try {
          var err = JSON.parse(http.response);
          emailError.innerText = err.meta.error_message;
          //eyeClosed.classList.add('err');
          //eyeOpen.classList.add('err');
          email.classList.add("err");
        } catch (er) {
          emailError.innerText = "Something went wrong. Please try again.";
          //eyeClosed.classList.add('err');
          //eyeOpen.classList.add('err');
          email.classList.add("err");
          try {
            createIncident(
              er.message,
              er +
                "\n\nEmail: " +
                email.value +
                "\n\nLogRocket URL: " +
                LogRocket.recordingURL
            );
          } catch (error) {
            console.log("GTM error");
          }
        } finally {
          tick.style.display = "none";
          tickLoader.style.display = "none";
          emailError.style.display = "block";
          //eyeClosed.classList.add('err');
          //eyeOpen.classList.add('err');
          email.classList.add("err");
          try {
            createIncident(
              err.meta.error_message,
              JSON.stringify(err.meta) +
                "\n\nEmail: " +
                email.value +
                "\n\nLogRocket URL: " +
                LogRocket.recordingURL
            );
          } catch (error) {
            console.log("GTM error");
          }
        }
      } else {
        if (env == "com") {
          var euUrl =
            "https://api.eu.squadcast.com/v3/accounts/register/validate";
          var euHttp = new XMLHttpRequest();
          try {
            euHttp.open("PUT", euUrl);
            euHttp.setRequestHeader("Content-type", "application/json");
            euHttp.send(JSON.stringify(params));
            date = new Date();
            reqTime = date.getTime();
            //startTimer();
            euHttp.onload = function () {
              date1 = new Date();
              resTime = date1.getTime();
              calcDiff("Validate API EU");
              //stopTimer('Validate API EU');
              if (euHttp.status != 200 && euHttp.status != 204) {
                try {
                  var err = JSON.parse(euHttp.response);
                  emailError.innerText = err.meta.error_message;
                  //eyeClosed.classList.add('err');
                  //eyeOpen.classList.add('err');
                  email.classList.add("err");
                } catch (er) {
                  emailError.innerText =
                    "Something went wrong. Please try again.";
                  //eyeClosed.classList.add('err');
                  //eyeOpen.classList.add('err');
                  email.classList.add("err");
                  try {
                    createIncident(
                      er.message,
                      er +
                        "\n\nEmail: " +
                        email.value +
                        "\n\nLogRocket URL: " +
                        LogRocket.recordingURL
                    );
                  } catch (error) {
                    console.log("GTM error");
                  }
                } finally {
                  tick.style.display = "none";
                  tickLoader.style.display = "none";
                  emailError.style.display = "block";
                  //eyeClosed.classList.add('err');
                  //eyeOpen.classList.add('err');
                  email.classList.add("err");
                  try {
                    createIncident(
                      err.meta.error_message,
                      JSON.stringify(err.meta) +
                        "\n\nEmail: " +
                        email.value +
                        "\n\nLogRocket URL: " +
                        LogRocket.recordingURL
                    );
                  } catch (error) {
                    console.log("GTM error");
                  }
                }
              } else {
                tick.style.display = "inline";
                tickLoader.style.display = "none";
                emailError.innerText = "";
                emailError.style.display = "none";
                //eyeClosed.classList.remove('err');
                //eyeOpen.classList.remove('err');
                email.classList.remove("err");
                //email.setAttribute('readonly','');
                //email.onkeyup = '';
                if (tk && isPasswordValid() && tick.style.display == "inline") {
                  getStarted.removeAttribute("disabled");
                }
              }
            };
          } catch (e) {
            tick.style.display = "none";
            tickLoader.style.display = "none";
            emailError.innerText = "Something went wrong. Please try again.";
            emailError.style.display = "block";
            //eyeClosed.classList.add('err');
            //eyeOpen.classList.add('err');
            email.classList.add("err");
            try {
              createIncident(
                e.message,
                e +
                  "\n\nEmail: " +
                  email.value +
                  "\n\nLogRocket URL: " +
                  LogRocket.recordingURL
              );
            } catch (error) {
              console.log("GTM error");
            }
          }
        } else {
          tick.style.display = "inline";
          tickLoader.style.display = "none";
          emailError.innerText = "";
          emailError.style.display = "none";
          //eyeClosed.classList.remove('err');
          //eyeOpen.classList.remove('err');
          email.classList.remove("err");
          //email.setAttribute('readonly','');
          //email.onkeyup = '';
          if (tk && isPasswordValid() && tick.style.display == "inline") {
            getStarted.removeAttribute("disabled");
          }
        }
      }
    };
  } catch (e) {
    tick.style.display = "none";
    tickLoader.style.display = "none";
    emailError.innerText = "Something went wrong. Please try again.";
    emailError.style.display = "block";
    //eyeClosed.classList.add('err');
    //eyeOpen.classList.add('err');
    email.classList.add("err");
    try {
      createIncident(
        e.message,
        e +
          "\n\nEmail: " +
          email.value +
          "\n\nLogRocket URL: " +
          LogRocket.recordingURL
      );
    } catch (error) {
      console.log("GTM error");
    }
  }
}

function register() {
  var params = {
    email: email.value,
    password: password.value,
    token: tk,
  };
  var http = new XMLHttpRequest();
  var url = path + "/v3/accounts/website/register-trial";
  try {
    http.open("POST", url);
    http.setRequestHeader("Content-type", "application/json");
    http.send(JSON.stringify(params));
    date = new Date();
    reqTime = date.getTime();
    //startTimer();
    http.onload = function () {
      date1 = new Date();
      resTime = date1.getTime();
      calcDiff("Register API");
      //stopTimer('Register API');
      if (http.status == 200 || http.status == 204) {
        auth = JSON.parse(http.response);
        regError.innerText = "";
        regError.style.display = "none";
        if (auth.data.used_personal_email == true) {
          //personal email logic
          try {
            gtmanager("gmailSignup", "gmail-signup");
          } catch (error) {
            console.log("GTM error");
          }
        } else {
          try {
            localStorage.setItem("email", email.value);
          } catch (error) {
            console.log("Cookies disabled");
          } finally {
            //window.location.href = 'https://'+host+'/set-up?env='+env+'&token='+tk+'&auth='+auth.data.token+'&eu='+document.getElementById('eu-2').checked;
            document.getElementById("pg1").style.display = "none";
            document.getElementById("pg2").style.display = "block";
            document.getElementById("st2").classList.add("selected");
            document.getElementById("st2text").classList.remove("white-text");
          }
        }
        try {
          gtmanager("allSignup", "all-signup");
        } catch (error) {
          console.log("GTM error");
        }
      } else {
        try {
          var err = JSON.parse(http.response);
          regError.innerText = err.meta.error_message;
        } catch (er) {
          regError.innerText = "Something went wrong. Please try again.";
          try {
            createIncident(
              er.message,
              er +
                "\n\nEmail: " +
                email.value +
                "\n\nLogRocket URL: " +
                LogRocket.recordingURL
            );
          } catch (error) {
            console.log("GTM error");
          }
        } finally {
          regError.style.display = "block";
          getStarted.removeAttribute("disabled");
          gsText.style.visibility = "visible";
          gsLoader.style.display = "none";
          tk = "";
          try {
            createIncident(
              err.meta.error_message,
              JSON.stringify(err.meta) +
                "\n\nEmail: " +
                email.value +
                "\n\nLogRocket URL: " +
                LogRocket.recordingURL
            );
          } catch (error) {
            console.log("GTM error");
          }
        }
      }
    };
  } catch (e) {
    regError.style.display = "block";
    regError.innerText = "Something went wrong. Please try again.";
    getStarted.removeAttribute("disabled");
    gsText.style.visibility = "visible";
    gsLoader.style.display = "none";
    tk = "";
    try {
      createIncident(
        e.message,
        e +
          "\n\nEmail: " +
          email.value +
          "\n\nLogRocket URL: " +
          LogRocket.recordingURL
      );
    } catch (error) {
      console.log("GTM error");
    }
  }
}

if (!geo) {
  geo = "Asia/Calcutta";
}

/*
if(eu == 'true'){
	path = 'https://api.eu.squadcast.com';
}else if(env == 'xyz'){
	path = 'https://api.squadcast.xyz';
}else if(env == 'tech'){
	path = 'https://api.squadcast.tech';
}else{
	path = 'https://api.squadcast.com';
}

//Timer starts
var minutesLabel = 0;
var secondsLabel = 0;
var totalSeconds = 0;
var interval;

function setTime() {
  ++totalSeconds;
  secondsLabel = pad(totalSeconds % 60);
  minutesLabel = pad(parseInt(totalSeconds / 60));
}

function pad(val) {
  var valString = val + "";
  if (valString.length < 2) {
    return "0" + valString;
  } else {
    return valString;
  }
}

function startTimer(){
	interval = setInterval(setTime, 1000);
}

function stopTimer(api){
	clearInterval(interval);
  timerIncident(api);
	minutesLabel = 0;
  secondsLabel = 0;
  totalSeconds = 0;
}

function timerIncident(ev){
	try{
		if(minutesLabel > 0 || secondsLabel > 5){
    	createIncident(ev+" "+"Latency","Time Taken: "+minutesLabel+":"+secondsLabel+"\n\nEmail: "+email+"\n\nLogRocket URL: "+LogRocket.recordingURL);
    }
  }catch(e){
  	console.log('GTM / Logrocket Error');
 }
}
//Timer ends

//Date Timer starts

var date;
var date1;
var reqTime;
var resTime;

function calcDiff(apiLabel){
	var diff = (resTime - reqTime)/1000;
  console.log(diff);
  if(diff > 5){
  	createIncident(apiLabel+" "+"Latency","Time Taken: "+diff+" Seconds\n\nEmail: "+email+"\n\nLogRocket URL: "+LogRocket.recordingURL);
  }
}

//Date Timer ends

//Listen for timeout starts (for tracking dropoffs)
timer = setTimeout(function(){
	createIncident("Incomplete Signup","Timeout of 5 minutes. Token expired.\n\nEmail: "+email+"\n\nLogRocket URL: "+LogRocket.recordingURL);
  timerRunning = false;
  try{
  	localStorage.removeItem("email");
  }catch(error){
    console.log('Cookies disabled');
  }
},5*1000*60);

//Listen for timeout ends

//Listen for reload/tab closed starts (for tracking dropoffs)
window.addEventListener('beforeunload', function (e) {
	if(timerRunning == true){
		createIncident("Incomplete Signup","Closed Tab/Page Reloaded.\n\nEmail: "+email+"\n\nLogRocket URL: "+LogRocket.recordingURL);
    try{
  	 	localStorage.removeItem("email");
    }catch(error){
      console.log('Cookies disabled');
    }
  }
}, false);

//Listen for reload/tab closed ends
*/

iBtn.onmouseover = function () {
  toolTip.style.display = "block";
};
iBtn.onmouseout = function () {
  toolTip.style.display = "none";
};

function validationCheck() {
  if (
    fnameError.style.display == "block" ||
    lnameError.style.display == "block" ||
    error.style.display == "block" ||
    orgError.style.display == "block" ||
    !fname.value ||
    !org.value
  ) {
    update.setAttribute("disabled", "");
  } else {
    update.removeAttribute("disabled");
  }
}

function gtmanager(event, formid) {
  dataLayer.push({
    event: event,
    "gtm.elementId": formid,
  });
}

fname.onkeyup = function () {
  if (!regx.test(fname.value) || !fname.value) {
    fnameError.innerText =
      "This field cannot contain special characters, numbers or be empty. Please provide a valid name.";
    fnameError.style.display = "block";
    fname.classList.add("err");
  } else {
    fnameError.style.display = "none";
    fnameError.innerText = "";
    fname.classList.remove("err");
    error.innerText = "";
    error.style.display = "none";
  }
  validationCheck();
};

lname.onkeyup = function () {
  if (!regx.test(lname.value)) {
    lnameError.innerText =
      "This field cannot contain special characters or numbers. Please provide a valid name.";
    lnameError.style.display = "block";
    lname.classList.add("err");
  } else {
    lnameError.style.display = "none";
    lnameError.innerText = "";
    lname.classList.remove("err");
    error.innerText = "";
    error.style.display = "none";
  }
  if (!lname.value) {
    lnameError.style.display = "none";
    lnameError.innerText = "";
    lname.classList.remove("err");
    error.innerText = "";
    error.style.display = "none";
  }
  validationCheck();
};

org.onkeyup = function () {
  if (!org.value) {
    orgError.innerText =
      "This field cannot be empty. Please provide a valid organization name.";
    orgError.style.display = "block";
    org.classList.add("err");
  } else {
    orgError.style.display = "none";
    orgError.innerText = "";
    org.classList.remove("err");
    error.innerText = "";
    error.style.display = "none";
  }
  validationCheck();
};
update.onclick = function (e) {
  e.preventDefault();
  try {
    window.lintrk("track", { conversion_id: 7841748 });
  } catch (e) {
    console.log("LinkedIn Error");
  } finally {
    update.setAttribute("disabled", "");
    updateText.style.visibility = "hidden";
    updateLoader.style.display = "block";
    onboard();
  }
};

function onboard() {
  var params = {
    first_name: fname.value,
    last_name: lname.value,
    contact: {
      dial_code: dialCode,
      phone_number: phoneNo,
    },
    organization: org.value,
    time_zone: geo,
    trial_mode: true,
  };
  var url = path + "/v3/accounts/website/onboard";
  var http = new XMLHttpRequest();
  try {
    http.open("POST", url);
    http.setRequestHeader("Authorization", "Bearer " + auth.data.token);
    http.setRequestHeader("Content-type", "application/json");
    http.send(JSON.stringify(params));
    date = new Date();
    reqTime = date.getTime();
    //startTimer();
    http.onload = function () {
      date1 = new Date();
      resTime = date1.getTime();
      calcDiff("Onboard API");
      //stopTimer('Onboard API');
      if (http.status == 200 || http.status == 204) {
        try {
          gtmanager("customSignup", "custom-signup");
        } catch (error) {
          console.log("GTM error");
        }
        error.innerText = "";
        error.style.display = "none";
        updateText.style.visibility = "visible";
        updateLoader.style.display = "none";
        updateText.innerText = "UPDATED";
        var redirect = JSON.parse(http.response);
        //clearTimeout(timer); //(for tracking dropoffs)
        //timerRunning = false; //(for tracking dropoffs)
        try {
          localStorage.removeItem("email");
        } catch (error) {
          console.log("Cookies disabled");
        } finally {
          window.location.href = redirect.data.redirect_url;
        }
      } else {
        try {
          var err = JSON.parse(http.response);
          error.innerText = err.meta.error_message;
        } catch (er) {
          error.innerText = "Something went wrong. Please try again.";
          try {
            createIncident(
              er.message,
              er +
                "\n\nEmail: " +
                email +
                "\n\nOrganization: " +
                org.value +
                "\n\nLogRocket URL: " +
                LogRocket.recordingURL
            );
          } catch (error) {
            console.log("GTM error");
          }
        } finally {
          error.style.display = "block";
          update.removeAttribute("disabled");
          updateText.style.visibility = "visible";
          updateLoader.style.display = "none";
          try {
            createIncident(
              err.meta.error_message,
              JSON.stringify(err.meta) +
                "\n\nEmail: " +
                email +
                "\n\nOrganization: " +
                org.value +
                "\n\nLogRocket URL: " +
                LogRocket.recordingURL
            );
          } catch (error) {
            console.log("GTM error");
          }
        }
      }
    };
  } catch (e) {
    error.innerText = "Something went wrong. Please try again.";
    error.style.display = "block";
    update.removeAttribute("disabled");
    updateText.style.visibility = "visible";
    updateLoader.style.display = "none";
    try {
      createIncident(
        e.message,
        e +
          "\n\nEmail: " +
          email +
          "\n\nOrganization: " +
          org.value +
          "\n\nLogRocket URL: " +
          LogRocket.recordingURL
      );
    } catch (error) {
      console.log("GTM error");
    }
  }
}

var hint = document.getElementById("hint");

function isPasswordValid() {
  return (
    password.value.length >= MIN_PASSWORD_LENGTH &&
    password.value.length <= MAX_PASSWORD_LENGTH
  );
}

function passwordEvent() {
  if (isPasswordValid()) {
    hint.style.display = "none";
  } else {
    hint.style.display = "inline";
  }

}

password.onkeyup = function () {
  passwordEvent();
};

password.onfocus = function () {
  passwordEvent();
  grecaptcha.ready(function () {
    grecaptcha
      .execute(RECAPTCHA_V3_SITE_KEY, { action: "submit" })
      .then(function (token) {
        tk=token
      });
  });
};
